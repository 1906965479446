<template>
  <div class="curriculum-system">
    <winner-banner/>
    <title-comp title-cn="课程系统" title-en="Curriculum System" style="margin: 100px 0"/>
    <div class="context">
      在长期的财商教育累积过程中，我们认为财商教育是基础教育，而不是兴趣（特长）教育。孩子财商的发展会影响到他的一生的发展，影响他的人生观、价值观、
      金钱观的塑造。我们希望通过《财商少年》系列课程协助孩子把财商融入生活，一起学习，修正计划并学会规划，从而给予孩子，一个确定而美好的未来。
    </div>

    <img class="points-title" src="../../assets/img/curriculum-system/points-title.png" alt="">

    <div class="feature__container">
      <div class="feature feature--blue">
        <div class="feature__title">专业性</div>
        <div class="feature__content">专属教学知识品牌，不可复制</div>
      </div>
      <div class="feature feature--green">
        <div class="feature__title">全面性</div>
        <div class="feature__content">课程内容全面规划，效果显著</div>
      </div>
      <div class="feature feature--yellow">
        <div class="feature__title">阶段性</div>
        <div class="feature__content">不同年龄阶段，课程体系多元化</div>
      </div>
      <div class="feature feature--red">
        <div class="feature__title">规划性</div>
        <div class="feature__content">多种教学理念及产品辅助教学</div>
      </div>
    </div>

    <div class="course">
      <div class="course-pro__left">
        <div class="course__number">01</div>
        <div class="course__box">
          <img src="../../assets/img/curriculum-system/photo-1.png" alt="">
        </div>
      </div>
      <div class="course-pro__right">
        <div class="course__title">幼儿英语财商课程（幼儿期）</div>
        <div class="course__content">跨学科教学，一个课堂同时收获两种学科知识，通过不同学科间的共同元素，利用传统学科作引导，让知识更易吸收，更易巩固。</div>
      </div>
    </div>

    <div class="course">
      <div class="course__left">
        <div class="course__title">少儿财商启蒙系列课程（6-12岁）</div>
        <div class="course__content">给小朋友进行财商启蒙教育，让其知道“钱从哪里来？”、“钱可以用来干什么？”等基础问题，让小朋友学到“收入”、
          “支出”、“记账”、“消费”等理财知识。通过互动游戏和情景模拟引导小朋友梳理正确的理财观念，养成良好的理财习惯。
        </div>
      </div>
      <div class="course__right">
        <div class="course__number">02</div>
        <div class="course__box">
          <img src="../../assets/img/curriculum-system/photo-2.png" alt="">
        </div>
      </div>
    </div>

    <div class="course course--yellow">
      <div class="course-pro__left">
        <div class="course__number">03</div>
        <div class="course__box">
          <img src="../../assets/img/curriculum-system/photo-3.png" alt="">
        </div>
      </div>
      <div class="course-pro__right">
        <div class="course__title">未来CEO养成计划课程（12-16岁）</div>
        <div class="course__content">未来CEO课程内容丰富，形式多样，涵盖银行、证券、和保险等各金融板块，同时融入模拟创业，工商注册等元素，
          通过财商游戏，寓教于乐；教会孩子管理零花钱，制定消费计划，为目标储蓄，以及养成记账等良好习惯；引导孩子区分想要和需要，规划消费和理财，
          认识时间和复利。财商课程，让孩子通过学习系统的财商知识，树立正确的财商观念，创造未来。
        </div>
      </div>
    </div>

    <div class="course">
      <div class="course__left">
        <div class="course__title">预演大学四年课程（16-18岁）</div>
        <div class="course__content">
          新颖的教学内容让孩子掌握与金钱打交道的必备本领，学习积极心理学建立正确的价值观和人生观。提升抵制网络诱惑的能力，锻炼出强大的内心，
          成为一个具有全方位国际视野的优秀青年。
        </div>
      </div>
      <div class="course__right">
        <div class="course__number">04</div>
        <div class="course__box">
          <img src="../../assets/img/curriculum-system/photo-4.png" alt="">
        </div>
      </div>
    </div>

    <div class="course course--green">
      <div class="course-pro__left">
        <div class="course__number">05</div>
        <div class="course__box">
          <img src="../../assets/img/curriculum-system/photo-5.png" alt="">
        </div>
      </div>
      <div class="course-pro__right">
        <div class="course__title">财商教育导师资格课程（成人）</div>
        <div class="course__content">
          实施财商导师培育项目，举全校之力整合最优质的师资，聘请国内外知名教师学者授课，在职业能力提升、理论知识运用、职业规划、案例分析、企业实践
          等方面予以指导，保证学员能够理论运用于实践，提升解决实际问题的能力。
        </div>
      </div>
    </div>

    <div class="course">
      <div class="course__left">
        <div class="course__title">《家庭理财规划课程》系列课程（成人）</div>
        <div class="course__content">
          家庭理财专属课程，领略科学理财的魅力。十大主题，涵盖各大理财板块，内容贴近生活，直击痛点，让我们清晰自身家庭财务状况，领略投资工具的特点，
          并学会用科学的方法去实现我们的理财目标。通过家庭理财课程，掌握家庭理财的奥秘，轻松理财，乐享生活。
        </div>
      </div>
      <div class="course__right">
        <div class="course__number">06</div>
        <div class="course__box">
          <img src="../../assets/img/curriculum-system/photo-6.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import WinnerBanner from "@/components/common/WinnerBanner";
  import TitleComp from "@/views/main/childComp/TitleComp";

  export default {
    name: "CurriculumSystem",
    components: {
      WinnerBanner,
      TitleComp
    }
  }
</script>

<style lang="scss" scoped>
  $red: #e40073;
  $green: #95c51b;
  $yellow: #ffe100;
  $blue: #00abeb;
  .curriculum-system {
    min-width: 1920px;
  }
  // 主要文字
  .context {
    width: 1320px;
    margin: 100px auto;
    font-size: 23px;
    color: #202730;
    text-align: center;
  }
  // 课程特色标题
  .points-title {
    width: 379px;
    height: 48px;
    display: block;
    margin: 0 auto 75px;
  }
  // 课程特色
  .feature__container {
    display: flex;
    width: 1320px;
    height: 330px;
    margin: 0 auto 100px;
  }
  .feature {
    width: 330px;
    text-align: center;
    color: #ffffff;
  }
  .feature--blue {
    background-color: $blue;
  }
  .feature--green {
    background-color: $green;
  }
  .feature--yellow {
    background-color: $yellow;
  }
  .feature--red {
    background-color: $red;
  }
  .feature__title {
    font-size: 36px;
    padding-top: 100px;
    margin-bottom: 25px;
  }
  .feature__content {
    font-size: 18px;
    width: 175px;
    margin: 0 auto;
  }

  // 课程介绍
  .course {
    display: flex;
    height: 480px;
    margin-bottom: 100px;
    justify-content: center;
  }
  .course__number {
    font-size: 230px;
    line-height: 0.7em;
    letter-spacing: -22px;
    font-weight: bold;
  }
  .course__box {
    width: 450px;
    height: 300px;
    position: absolute;
    border-radius: 10px;
    box-shadow: 10px 10px 5px 5px #00000022;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .course__title {
    width: 480px;
    height: 50px;
    line-height: 50px;
    font-size: 24px;
    color: #ffffff;
    border-radius: 25px;
    text-indent: 1em;
  }
  .course__content {
    width: 480px;
    font-size: 18px;
    color: #202730;
  }
  .course-pro__left {
    background-color: $red;
    width: 960px;
    overflow: hidden;
    position: relative;
    .course__number {
      color: #ee5ca6;
      margin-left: 310px;
    }
    .course__box {
      top: 90px;
      left: 400px;
      background-color: #e45ba0;
    }
  }
  .course-pro__right {
    width: 960px;
    height: 480px;
    background-image: url("../../assets/img/curriculum-system/curriculum-1.png");
    background-size: 100%;
    .course__title {
      margin-top: 100px;
      margin-left: 90px;
      background-color: $red;
    }
    .course__content {
      margin-left: 90px;
      margin-top: 25px;
    }
  }

  .course__left {
    width: 660px;
    background-color: #eeeeee;
    .course__title {
      background-color: $blue;
      margin: 90px auto 0;
    }
    .course__content {
      margin: 25px auto 0;
    }
  }
  .course__right {
    width: 660px;
    background-color: $blue;
    overflow: hidden;
    position: relative;
    .course__number {
      color: #5ccaf3;
      text-align: right;
    }
    .course__box {
      background-color: #5ec5eb;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  // 黄色的课程
  .course--yellow {
    .course-pro__left {
      background-color: $yellow;
    }
    .course__box {
      background-color: #ffed66;
    }
    .course__number {
      color: #ffed66;
    }
    .course__title {
      background-color: $yellow;
    }
  }

  // 绿色的课程
  .course--green {
    .course-pro__left {
      background-color: $green;
    }
    .course__box {
      background-color: #abc56a;
    }
    .course__number {
      color: #abc56a;
    }
    .course__title {
      background-color: $green;
    }
  }
</style>